<script setup lang="ts">
import {ref} from 'vue'
import MainManu from './MainMenu.vue';
import { useCarsStore } from 'vuebase';
import {useRoute,useRouter } from 'vue-router';
import {useUtilities} from 'vuebase';
const route = useRoute();
const router = useRouter();
 const removeCar =(()=> {
  if(useCarsStore().selected_car){
    useCarsStore().clear_Car();
    let query = route.query
    delete query.vehicle
    const timestamp = Date.now();
    router.push({
      path:route.path,
      query: { ...query,_t:timestamp }
    })
    useUtilities().productloading=true
  }
})
const emit = defineEmits(['CallOpenGarage']);
const openGarage = (()=>{
  emit('CallOpenGarage');
})

const opegegaracecar = ref(false)
</script>

<template>
  <div class="row frsc-sr py-4 pe-4">
    <div class="col-lg-9 d-flex align-items-center">
      <!-- Navigation Links -->
      <div class="text-center">
        <MainManu />
      </div>
    </div>
    <div class="col-lg-3">
      <!-- My Garage -->
      <div :title="useCarsStore().selected_car!=null?useCarsStore().selected_car?.car?.name:'Select Your Car'" class="d-flex align-items-center justify-content-end gap-2">
        <div data-bs-toggle="offcanvas" data-bs-target="#mycargarage" aria-controls="mycargarage">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 2048 2048"><path fill="#ff2400" d="M384 1152q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10m1280 0q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10m347-256l-66 65q2 5 10 30t19 59t25 73t24 71t18 54t7 22v650q0 27-10 50t-27 40t-41 28t-50 10h-128q-27 0-50-10t-40-27t-28-41t-10-50H384q0 27-10 50t-27 40t-41 28t-50 10H128q-27 0-50-10t-40-27t-28-41t-10-50v-650l7-21l18-54l24-72q13-39 24-73t20-59t10-30l-66-65H0V768h91l57 58l74-223q16-49 46-89t71-69t87-45t100-16h996q52 0 99 16t88 44t70 69t47 90l74 223l57-58h91v128zM526 512q-63 0-112 36t-70 95l-85 253h1530l-85-253q-20-59-69-95t-113-36zm882 1231l-104-207H744l-104 207v49h768zm512 49v-502l-6-18q-6-18-15-47t-21-61t-21-63t-17-51t-9-26H217q-2 5-9 26t-17 50t-21 63t-20 62t-16 46t-6 19v502h384v-79l152-305h720l152 305v79z"/></svg>
          </span>
        </div>
        <div class="d-flex flex-column my-garage" @mouseleave="opegegaracecar=false" @mouseenter="opegegaracecar=true">
          <button class="d-flex flex-column border-0 bg-transparent" @click="openGarage()">
            <span class="text-start" style="line-height: 0.7;font-weight: 600;">My Garage</span>
            <span class="small fw-light" v-if="useCarsStore().selected_car && useCarsStore().selected_car.car">{{(useCarsStore().selected_car.car.name).substring(0,20)}}...</span>
            <span class="small fw-light" v-else>Select Your Car</span>
          </button>
        <div class="garagecarpop" v-if="useCarsStore().selected_car && useCarsStore().selected_car.car!=undefined && opegegaracecar==true">
            <small class="text-success fw-light fw-bold">{{(useCarsStore().selected_car.car.name)}}</small>
            <button class="text-danger carremvoebutton d-block p-0 border-0 bg-white text-start"><small @click="removeCar()" v-if="useCarsStore().selected_car && useCarsStore().selected_car.car">Remove</small></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.my-garage{
  position: relative;
}
.garagecarpop{
  position: absolute;
  box-shadow: 0 0 5px;
  padding: 5px 15px;
  width: 180px;
  top:calc(100% - 0px);
  right: 0;
  background-color: #fff;
  &:before{
    content:"";
    width:15px;
    height:15px;
    position:absolute;
    z-index: 0;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    background-color: #fff;
    top:-7px;
    transform: rotate(45deg);
    left:calc(50% - 10px);
  }
}
.carremvoebutton{
  color:var(--bs-secondary) !important;
  &:hover{
    color:var(--bs-primary) !important;
  }
}
</style>
