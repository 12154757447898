<script setup lang="ts">
import {ref, watch} from 'vue'
import HeaderScript from "vuebase/templates/Header";
import { useRoute, useRouter } from 'vue-router'
const {
  menuData
} = HeaderScript.setup();
const route = useRoute()
const router = useRouter()

const MegamenuOpened = ref(false)
const openMegmENU = (() => {
 
    MegamenuOpened.value = true;

})

watch(() => router.currentRoute.value, (newValue, oldValue) => {
  MegamenuOpened.value = false;
  
})
</script>

<template>
  <nav>
    <ul class="list-unstyled d-flex mb-0 gap-3" v-if="menuData">
      <li class="nav-item-one" v-for="(item, index) in menuData.items" :key="item.sortorder || index" @mouseover="openMegmENU">
        <!-- Parent Item Link -->
        
        <router-link v-if="item.url!='#' && item.url!=''" :class="{ 'nav-link-active': route.path.includes(item.url) }" :to="'/'+ item.url" class="text-success fw-bold text-decoration-none fs-20">
          {{ item.title }}
        </router-link>
        <a href="javascript:void(0)" v-else class="text-success fw-bold text-decoration-none fs-20" :class="{ 'nav-link-active': route.path.includes(item.url) }">{{ item.title }}</a>
        <div
          class="container-fluid p-5 position-absolute w-100 bg-white start-0 border-top border-secondary border-2 navfcon shadow"
          v-if="(item.children && item.children.length > 0) && MegamenuOpened" id="menuMega">
          <div class="row navfr">
            <div class="col-sm-3 submenu-column navfrfc" v-for="(child, childIndex) in item.children" :key="childIndex">
              <div v-if="child.children && child.children.length > 0">
                <div class="submenu-column navfrfc mt-4" v-for="(supChild, supChildIndex) in child.children"
                  :key="supChildIndex">
                  <h5 class="fw-semibold fs-20 text-info pb-2 text-start border-bottom border-3 border-primary" :class="{
                    'border-bottom-0': item.title !== 'Shop',
                  }">
                    <router-link :to="'/' + supChild.url" class="fw-semibold fs-20 text-decoration-none text-info">{{
                      supChild.title }}</router-link>
                  </h5>

                  <!-- Recursively render child's children if available -->
                  <ul class="list-unstyled">
                    <li v-for="(
                            subChild, subChildIndex
                          ) in supChild.children" :key="subChildIndex" class="text-start mb-2">
                      <router-link :to="'/' + subChild.url" class="fw-semibold fs-20 text-decoration-none text-info">{{
                        subChild.title }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>
<style lang="scss">
  .router-link-active.nav-link-active{
  color: #ff2400 !important;
}
</style>