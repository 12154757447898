<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useCarsStore } from 'vuebase'
import { Offcanvas } from 'bootstrap';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute()
const router = useRouter()
const CarsStore = useCarsStore();

const ValidFirstStep = ref(false);

onMounted(async () => {
    const myOffcanvas = document.getElementById('mycargarage');

    if (myOffcanvas) {
        // Ensure the element exists before interacting with it
        const bsOffcanvas = new Offcanvas(myOffcanvas);
        bsOffcanvas.show();
    } else {
        console.warn('Offcanvas element not found: #mycargarage');
    }
    await CarsStore.getMake();
});
const CurentStep = ref(1);
const CarFields = ref({});

const CarMakes = computed(() => CarsStore.makeData)
const CarmakeYears = computed(() => CarsStore.makeYears)
const CarModels = computed(() => CarsStore.makeModels)
const selected_car = computed(() => CarsStore.selected_car)
const SelectMake = (async (make) => {
    await CarsStore.getYears(make)
});

const SelectYear = (async (make, year) => {
    await CarsStore.getModel(make, year)
});

const SelectModel = (async (model) => {
    await CarsStore.selectedCar(model)
    // ValidFirstStep.value=true
    filterbycar();

});
const SelectedCar = computed(() => CarsStore.selected_car)
const filterbycar = (() => {
    let queryparams = route.query;
    const currentRouteName = route.name;
    // const targetRouteName = route.meta.paged_route || route.name;
    // const targetRouteName = currentRouteName === 'Home' ? 'Shop' : (route.meta.paged_route || currentRouteName);
    const isOnShopOrCategory = currentRouteName === 'Shop' || currentRouteName === 'Category';

    const targetRouteName = isOnShopOrCategory ? (route.meta.paged_route || currentRouteName) : 'Shop';
    const pageNumber = 1;
    const slug = route.params.slug

    queryparams.vehicle = SelectedCar.value.car.id

    delete queryparams.page;
    const timestamp = Date.now();
    router.push({
        name: targetRouteName,
        params: {
            slug: slug,
            pagenumber: pageNumber

        },
        query: { ...queryparams, _t: timestamp }
    });
});
</script>
<template>
    <div class="offcanvas offcanvas-end garage-sidebar" tabindex="-1" id="mycargarage" aria-labelledby="mycargarage">
        <div class="offcanvas-header">
            <div class="icon-box me-2"><i class="fas fa-car">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                        <g fill="none" fill-rule="evenodd">
                            <path
                                d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
                            <path fill="#ff2400"
                                d="M15.764 4a3 3 0 0 1 2.683 1.658l1.386 2.771q.366-.15.72-.324a1 1 0 0 1 .894 1.79a32 32 0 0 1-.725.312l.961 1.923A3 3 0 0 1 22 13.473V16a3 3 0 0 1-1 2.236V19.5a1.5 1.5 0 0 1-3 0V19H6v.5a1.5 1.5 0 0 1-3 0v-1.264c-.614-.55-1-1.348-1-2.236v-2.528a3 3 0 0 1 .317-1.341l.953-1.908q-.362-.152-.715-.327a1.01 1.01 0 0 1-.45-1.343a1 1 0 0 1 1.342-.448q.355.175.72.324l1.386-2.77A3 3 0 0 1 8.236 4zM7.5 13a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m9 0a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m-.736-7H8.236a1 1 0 0 0-.832.445l-.062.108l-1.27 2.538C7.62 9.555 9.706 10 12 10c2.142 0 4.101-.388 5.61-.817l.317-.092l-1.269-2.538a1 1 0 0 0-.77-.545L15.765 6Z" />
                        </g>
                    </svg>
                </i></div>
            <h5 class="offcanvas-title fw-bold text-dark ps-2" id="mycargarageLabel">My Garage</h5>
            <div class="ps-2">
                <div class="fs-12 ps-2 fw-medium">Add your vehicles here to shop </div>
                <div class="fs-12 ps-2 fw-medium">products that fit.</div>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="middle-sec">
                <form id="submit-car-inquiry-form" method="post">
                    <div id="car-details" class="stepone" v-show="CurentStep == 1">
                        <h5>Let's start by adding your vehicle details.</h5>
                        <div class="mb-3">
                            <label for="input_1" class="form-label">Make</label>

                            <select class="form-select" v-model="CarFields.make" @change="SelectMake(CarFields.make)"
                                id="input_1">
                                <option value="" selected>Select Make</option>

                                <template v-for="make in CarMakes">
                                    <option :selected :value="make">{{ make.name }}</option>
                                </template>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="selected_year_id" class="form-label">Year</label>
                            <select class="form-select" v-model="CarFields.year"
                                @change="SelectYear(CarFields.make, CarFields.year)" id="selected_year_id">
                                <option value="" selected>Select Year</option>
                                <template v-for="year in CarmakeYears">
                                    <option :value="year">{{ year }}</option>
                                </template>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="selected_model_id" class="form-label">Model</label>
                            <select class="form-select" @change="ValidFirstStep = true" v-model="CarFields.model"
                                id="selected_model_id">
                                <option value="" selected>Select Model</option>
                                <template v-for="model in CarModels">
                                    <option :value="model">{{ model.name }}</option>
                                </template>
                            </select>
                        </div>
                        <p class="text-danger mt-2 fields-error"></p>
                        <button data-bs-dismiss="offcanvas" type="button" @click="SelectModel(CarFields.model)"
                            :disabled="ValidFirstStep == false" class="btn btn-lg btn-primary rounded-2 mt-4"
                            id="next-customer-details">Add
                            Car</button>
                    </div>
                    <div id="inquiry-fields" class="steptwo" v-show="CurentStep == 2">
                        <span @click="CurentStep = 1" id="change-car" class="btn btn-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    d="M7.499 6.497L3.5 10.499l4 4.001m9-4h-13" />
                            </svg>
                            Change Car
                        </span>
                        <p class="text-center">Thanks! We are working on our automated finder as fast as we can. While
                            we wait our
                            specialists are still waiting to answer your car / application specific questions. Shoot us
                            your query now. We
                            will come back to you ASAP.</p>
                        <div class="mb-3">
                            <label for="first_name" class="form-label">First Name</label>
                            <input type="text" class="form-control" id="first_name" name="first_name" required>
                        </div>
                        <div class="mb-3">
                            <label for="last_name" class="form-label">Last Name</label>
                            <input type="text" class="form-control" id="last_name" name="last_name" required>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" id="email" name="email" required>
                        </div>
                        <div class="mb-3">
                            <label for="phone" class="form-label">Phone No</label>
                            <input type="text" class="form-control" id="phone" name="phone" maxlength="10"
                                pattern="[0-9]+" required>
                        </div>
                        <div class="mb-3">
                            <label for="stores" class="form-label">Stores</label>
                            <select class="form-select" id="stores">
                                <option value="" selected>Select Store</option>
                                <option value="Store 1">Store 1</option>
                                <option value="Store 2">Store 2</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label>Message</label>
                            <textarea rows="4" cols="50" class="form-control shadow-sm rounded"
                                style="width: 100%;background-color: #f9f9f9; resize: none;"
                                placeholder="Tell Us As Much As You Can About Your Specific Application / Needs."></textarea>
                        </div>
                        <div>
                            <button class="btn btn-custom btn-tran" type="submit">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.icon-box {
    position: relative;

    &:after {

        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: calc(50% - 6px);
        right: -6px;
        background: #d2d4d5;
        transform: rotate(45deg);
    }
}
</style>