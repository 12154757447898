<script setup>
import { ref,onMounted } from 'vue'
import { useRoute } from 'vue-router'
import ChildItem from './ChildItem.vue'
const route = useRoute()
const props = defineProps({
  menuData: Array
})
const openChildMenu = (eleemnt)=>{
  console.log(eleemnt)
}

onMounted (()=>{
  document.querySelectorAll('.mobilemenu .menu_open, .menu_item_has_child > a').forEach(function (element) {
    element.addEventListener('click', function (event) {
        event.preventDefault();

        // Remove 'current' class from all elements with class 'menu_item_has_child'
        document.querySelectorAll('.menu_item_has_child').forEach(function (item) {
            item.classList.remove('current');
        });

        // Add 'active' and 'current' classes to the clicked element's parent with class 'menu_item_has_child'
        var parentElement = element.parentElement;
        if (parentElement && parentElement.classList.contains('menu_item_has_child')) {
            parentElement.classList.add('active', 'current');
            parentElement.style.display = 'block';
        }

        // Hide all 'li' elements inside the element with class 'mobilemenu'
        document.querySelector('.mobilemenu').querySelectorAll('li').forEach(function (liElement) {
            liElement.style.display = 'none';
        });

        // Toggle the 'test' class on the children with class 'mega_menu'
        // var megaMenu = element.querySelector('.mega_menu');
        // if (megaMenu) {
        //     megaMenu.classList.toggle('test');
        //     if (megaMenu.classList.contains('test')) {
        //         megaMenu.classList.remove('d-none');
        //     } else {
        //         megaMenu.classList.add('d-none');
        //     }
        // }
    });
});

document.addEventListener('click', function(event) {
  if (event.target.classList.contains('mobile-menu-back')) {
    event.preventDefault();

    var currentMenuItem = event.target.closest('.menu_item_has_child.current');
    
    if (currentMenuItem) {
      currentMenuItem.classList.remove('active');
      currentMenuItem.classList.remove('current');

      var parentMenuItem = currentMenuItem.parentElement.closest('.menu_item_has_child.active');
      if (parentMenuItem) {
        parentMenuItem.classList.add('current');
      }

      var mobileMenu = document.querySelector('.mobilemenu');
      var activeChildMenuItems = mobileMenu.querySelectorAll('.menu_item_has_child.active');

      if (activeChildMenuItems.length === 0) {
        var menuItems = mobileMenu.querySelectorAll('li');
        menuItems.forEach(function(menuItem) {
          menuItem.style.display = 'block';
        });
      }
    }
  }
});
document.addEventListener('click', function (event) {
  const targetClassList = event.target.classList;

  if (targetClassList.contains('close_btn') || targetClassList.contains('overlay')) {
    document.querySelector('.sidebar_mobile_menu').classList.remove('active');
    document.querySelector('.overlay').classList.remove('active');
  }
});

// document.querySelector('.mobile_menu_btn').addEventListener('click', function () {
//   document.querySelector('.sidebar_mobile_menu').classList.add('active');
//   document.querySelector('.overlay').classList.add('active');
// });
document.addEventListener('DOMContentLoaded', function () {
  const mobileMenuBtn = document.querySelector('.mobile_menu_btn');
  const sidebarMobileMenu = document.querySelector('.sidebar_mobile_menu');
  const overlay = document.querySelector('.overlay');

  if (mobileMenuBtn && sidebarMobileMenu && overlay) {
    mobileMenuBtn.addEventListener('click', function () {
      sidebarMobileMenu.classList.add('active');
      overlay.classList.add('active');
    });
  } else {
    console.error('One or more elements not found!');
  }
});


})

</script>
<template>

  <template v-for="item in props.menuData.items">
    
  <ChildItem :menuData="item" v-if="item.children"/>
  <li class="" v-else=""><router-link class="nav-link nav-child-link child-link"
      :class="{ 'nav-link-active': route.path.includes(item.url) }" :to="'/' +item.url">
      {{ item.title }} 
    </router-link></li>
</template>

</template>
