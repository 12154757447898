<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router';
import { useSearchStore } from 'vuebase';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const { resultshow, searchValue } = defineProps(['resultshow', 'searchValue'])
const emit = defineEmits('HideSearch')
const hideresult = (() => {
  emit('HideSearch');
})
const limitedProducts = (results: any) => {
  return results.slice(0, 3);
}
const searchData = computed(() => useSearchStore().data)

</script>
<template>

  <div class="search_result_wrapper" @mouseleave="hideresult" v-if="searchData != null && resultshow == true">
    <div class="d-block d-md-none search_close" @click="hideresult"><i class="fal fa-times"><svg
          xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
          <path fill="black"
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
        </svg></i></div>
    <div class="result_body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4 border-right">
            <div class="suggestions d-none d-md-flex gap-3">
              <h5 class="w-25 fw-bold">Categories</h5>
              <ul class="list-unstyled w-100 ml-0 ml-md-5">
                <template v-for="cat in searchData.categories">
                  <li>
                    <RouterLink @click="hideresult" :to="{ path: '/' + cat.slug }">
                      {{ cat.name }}
                    </RouterLink>
                  </li>
                </template>
              </ul>
            </div>
            <div class="brands d-none d-md-flex gap-3">
              <h5 class="w-25 fw-bold">Brands</h5>
              <ul class="list-unstyled w-100 ml-0 ml-md-5"></ul>
            </div>
          </div>
          <div class="col-lg-8 px-0 px-md-5">
            <h5 class="fw-bold">Matching Products</h5>
            <div class="row" v-if="searchData.products.data && searchData.products.data.length > 0">
              <template v-for="product in limitedProducts(searchData.products.data)">
                <div class="col-6 col-lg-4 p-0 p-md-1">
                  <div class="product-item">
                    <RouterLink @click="hideresult" :to="{ path: '/' + product.slug }" class="d-block">
                      <div class="imagecontainer"><img class="img-fluid" :src="product.image">
                      </div>
                      <p class="pro-title mt-2 mb-1">{{ product.name }}</p>
                      <p class="mb-0 fw-bold pro-title"><b>{{ product.price }}</b></p>
                      <span v-if="product.rpfloat > product.pricefloat"
                        class=" text-decoration-line-through">{{ product.rp }}</span>
                    </RouterLink>
                  </div>
                </div>
              </template>
            </div>
            <div v-else>
              <p>No Product Found</p>
            </div>
            <div class="row mt-4" v-if="searchData.products.data && searchData.products.data.length >= 3">
              <div class="col text-center">
                <router-link class="search_button" @click="hideresult" :to="{
                  name: 'Search',
                  query: { search: searchValue },
                }">
                  View more
                </router-link>
              </div>
            </div><!---->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.search_result_wrapper {
  position: absolute;
  width: 100%;
  z-index: 99;
  background: #fff;
  top: 55%;
  left: 0;
  padding: 30px 20px;
  border-top: 2px solid #365072 !important;
  border-bottom: 2px solid #365072 !important;

  .result_body {
    .search_button {
      z-index: 0;
      height: 60px;
      line-height: 1;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      padding: 10px 35px;
      position: relative;
      align-items: center;
      display: inline-flex;
      letter-spacing: 0.5px;
      justify-content: center;
      border-radius: 10px;
      overflow: hidden;
      text-transform: uppercase;
      background-color: #ff2400;

      &:hover {
        &:before {
          left: 0;
          width: 100%;
          right: auto;
        }
      }

      &:before {
        top: 0px;
        background: #2e2e2e;
        width: 0px;
        left: auto;
        right: 0px;
        z-index: -1;
        bottom: 0px;
        content: "";
        position: absolute;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.hover-link {
  span {
    position: relative;
    z-index: 1;
  }

}

.search_result_wrapper:after {
  content: "";
  width: 40px;
  height: 40px;
  background: #ffffff;
  position: absolute;
  top: -21px;
  left: 30%;
  border-top: 2px solid #365072;
  border-left: 2px solid #365072;
  transform: rotate(45deg);
}

.search_result_wrapper .suggestions a {
  position: relative;
  display: block;
  color: #393939;
  font-family: "Rajdhani";
  padding: 5px;
  line-height: 1;
  font-size: 16px;
  font-weight: 400;
}

.search_result_wrapper .suggestions a:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ddd' d='M11 19.5V7.914l-4.5 4.5L5.086 11L12 4.086L18.914 11L17.5 12.414l-4.5-4.5V19.5z'/%3E%3C/svg%3E");
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 5px;
  top: 0;
  transform: rotate(-45deg);
  font-size: 18px;
  color: #dbd7d7;
  line-height: 100%;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Change the color to red */
.search_result_wrapper .suggestions a:hover::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ff0000' d='M11 19.5V7.914l-4.5 4.5L5.086 11L12 4.086L18.914 11L17.5 12.414l-4.5-4.5V19.5z'/%3E%3C/svg%3E");
}


.search_result_wrapper .suggestions a:hover {
  color: red;
  box-shadow: 0 0 5px #ddd;
}

.search_result_wrapper .suggestions a:hover:after {
  color: red;
}

.search_result_wrapper .brands a {
  position: relative;
  display: block;
  color: #393939;
  font-family: "Rajdhani";
  padding: 5px;
  line-height: 1;
  font-size: 16px;
  font-weight: 400;
}

.search_result_wrapper .brands a:hover {
  color: red;
  box-shadow: 0 0 5px #ddd;
}

.search_result_wrapper .col-lg-4.border-right {
  border-right: 2px solid #365072 !important;
}

.search_result_wrapper .product-item {
  padding: 10px;
}

.search_result_wrapper .product-item .pro-title {
  color: var(--bs-primary);
  font-size: 16px;
  font-weight: 400;
  font-family: "Rajdhani";
}

.search_result_wrapper .product-item .imagecontainer {
  height: 150px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_result_wrapper .product-item .imagecontainer img {
  border: 0px solid #365072;
  padding: 0px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.search_result_wrapper .product-item:hover {
  box-shadow: 0 0 5px #365072;
}

.search_result_wrapper .product-item:hover .pro-title {
  color: #ff2400;
}

.search_result_wrapper .custom_btn {
  border-radius: 10px !important;
  padding: 15px 30px !important;
  height: auto;
}

.header_section.sticky .search_result_wrapper {
  top: 60px;
  left: 0;
}

.header_section.sticky .search_result_wrapper:after {
  width: 30px;
  height: 30px;
  top: -17px;
}

.search_close {
  position: absolute;
  right: 39px;
  cursor: pointer;
  top: 1px;
  font-size: 30px;
  font-weight: 300;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_result_wrapper.searchfixed {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 100px);
  overflow: scroll;
}
</style>