<script setup lang="ts">
import FooterScript from "vuebase/templates/Footer";
import SubscriptionComponent from "./Subscription.vue";
const { FooterMenu } = FooterScript.setup();
</script>

<template>
  <footer class="pt-0 pt-md-5 bg-dark">
    <div class="container position-relative fcon">
      <!-- Subscribe Section -->
      <component :is="SubscriptionComponent" />
      <!-- Main Footer Section -->
      <div class="row text-center text-md-start pt-5 g-4">
        <!-- Logo and Contact Info -->
        <div class="col-lg-4 mb-3">
          <div class="d-flex justify-content-center justify-content-md-start align-items-center">
            <router-link :to="{ name: 'Home' }">
              <img src="@/assets/images-new/footer/logofooter.webp" alt="Logo" width="500" height="180" loading="lazy"
                class="me-3 img-fluid" />
            </router-link>
          </div>
          <div class="mt-4 text-start">
            <span class="fs-4 fw-bold"><a href="tel:(02)89998878" class="text-white text-decoration-none fw-bold">(02)
                8999
                8878</a></span>
          </div>
        </div>

        <div class="col-lg-4"></div>

        <!-- Customer Care Links -->
        <div class="col-lg-4">
          <h5 class="text-white text-start fw-bold fs-3 mb-3 d-none d-lg-block mb-4">Customer Care</h5>
          <h5 class="text-white text-start fw-bold fs-18 mb-3 d-lg-none mb-4">Customer Care</h5>
          <div class="">
            <FooterMenu :menu-data="menuData" />
          </div>
          <!-- <ul class="list-unstyled" style="column-count: 2">
            <li class="text-start">
              <a href="#" class="text-white text-decoration-none fw-semibold"
                >About</a
              >
            </li>
            <li class="text-start">
              <a href="#" class="text-white text-decoration-none fw-semibold"
                >Terms of Use</a
              >
            </li>
            <li class="text-start">
              <a href="#" class="text-white text-decoration-none fw-semibold"
                >Privacy Policy</a
              >
            </li>
            <li class="text-start">
              <a href="#" class="text-white text-decoration-none fw-semibold"
                >Returns & Exchanges</a
              >
            </li>
            <li class="text-start">
              <a href="#" class="text-white text-decoration-none fw-semibold"
                >Contact Us</a
              >
            </li>
            <li class="text-start">
              <a href="#" class="text-white text-decoration-none fw-semibold"
                >Shipping Policy</a
              >
            </li>
          </ul> -->
        </div>
      </div>
    </div>
    <div class="footer-bottom scon mt-3 py-2">
      <!-- Footer Bottom Section -->
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <!-- Copyright and Developer Info -->
          <div class="col-sm-auto my-2 text-center text-md-start mb-3 mb-md-0 order-3 order-lg-0">
            <p class="text-white mb-0 fw-medium small">
              Powered by
              <span class="">
                <a href="https://flowrix.com" target="_blank" class=" text-decoration-none fw-medium">FLOWRiX</a>
              </span>
              | Developed by
              <span class=""><a href="https://iversion.com.au" target="_blank"
                  class=" text-decoration-none fw-medium">iVersion</a></span>
            </p>
          </div>
          <div class="col-sm-auto my-2">
            <p class="text-white mb-0 text-center small">© Roof Rack and Towbar World.</p>
          </div>
          <!-- Payment Methods -->
          <div class="col-sm-auto my-2 text-center text-md-end">
            <img src="@/assets/images-new/footer/payment-methods-03.png" alt="Payment Methods" class="img-fluid"
              loading="lazy" width="206" height="31" />
          </div>
        </div>
      </div>
    </div>

    <div class="get-in-touch">
      <router-link :to="{ name: 'Contact', hash: '#touch' }"
        class="router-link-active router-link-exact-active text-white text-decoration-none fs-24 fw-medium">Get in
        Touch</router-link>
    </div>
  </footer>
</template>
