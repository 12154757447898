<script setup lang="ts">
import HeaderScript from "vuebase/templates/Header";
import SearchResult from "./SearchResult.vue";
import { ref } from 'vue';
const {
  wordpressUrl,
  NavMenu,
  companyProfile,
  menuData,
  authStore,
  cartStore,
  userStore,
  userData,
  mobile_menu,
  MainMenu,
  OpenMobileMenu,
  bodyOwerflow,
  useSearch,
  getSearchData,
  handleSeachInput
} = HeaderScript.setup();
const searchValue = ref('')
const fromTop = ref(false)
const resultshow = ref(true)
const hideSearchResult = (()=>{
  resultshow.value = false
  fromTop.value=false
})
const triggerInputOnMouseEnter = (()=>{
  resultshow.value = true;
  getSearchData('');
})
</script>

<template>
  <div class="mob-search position-relative">
    <form class="position-absolute start-0 w-100" style="top: 50px">
      <div class="form_item mb-0 py-2 px-3 bg-white">
        <input
          @input="
            handleSeachInput(searchValue), (resultshow = true), (fromTop = true)
          "
          v-model="searchValue"
          @mouseleave="getSearchData(searchValue)"
          @focusin="
            handleSeachInput(searchValue), (resultshow = true), (fromTop = true)
          "
          class="form-control me-2 search-box-input rounded-0"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
        <span
          @click="(searchValue = ''), getSearchData(''), (resultshow = false)"
          v-if="searchValue != ''"
          class=""
          style="
            position: absolute;
            right: 90px;
            top: calc(50% - 10px);
            height: 20px;
            width: 20px;
          "
          type="button"
        >
        </span>
      </div>
    </form>
  </div>
<SearchResult :class="{'searchfixed':fromTop==true}" @HideSearch="hideSearchResult" :resultshow="resultshow"/>

</template>
