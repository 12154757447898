<script setup lang="ts">
import CompareScript from "vuebase/templates/Compare/CompareBar";
const {route,router,CompareStore,items,removeItem} = CompareScript.setup()
</script>
<template>
    <div :class="{'active':items.length>0 && route.name!='productcompare'}" class="product-compare-bar" id="product-compare-bar">
    <div class="container">
        <div class="row">
            <div class="col-md-10">
                <div class="row">
                    <template v-for="(item,index) in items">
                        <div class="col-md-3">
                            <div class="compare-item">
                                <div class="media d-flex gap-2 bg-white py-2 px-3 rounded h-100">
                                    <span class="remove-compare-item" @click="removeItem(item.id)">
                                        <i class="close">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path fill="#cdcdcd" d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"></path>
                                            </svg>
                                        </i>
                                    </span>
                                    <img :src="item.image" width="60px">
                                    <div class="media-body">
                                        <h6>
                                            <router-link class="fw-bold" :to="{name:'Product',params:{slug:item.slug}}">{{ item.name }}</router-link>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-if="items.length==1" class="col-md-3">
                        <div class="compare-item">
                            <p class="rounded">Please select at least 2 products to compare</p>
                        </div>
                    </div>
                    <div v-if="items.length==2" class="col-md-3">
                        <div class="compare-item">
                            <p class="rounded">Add up to 2 more items to compare</p>
                        </div>
                    </div>
                    <div v-if="items.length==3" class="col-md-3">
                        <div class="compare-item">
                            <p class="rounded">Add 1 more item to compare</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 text-center">
                <router-link :to="{name:'productcompare'}"  :class="{'disabled':items.length<2}" class="d-flex btn custom_btn bg_carparts_red bg_default_red p-0" rel="nofollow">Compare These Items</router-link>
                <a rel="nofollow" @click="CompareStore.items={}" href="#" class="clear-all-compare-items">Clear All Items</a>
            </div>
        </div>
    </div>
</div>
</template>