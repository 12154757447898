<script setup>
import { computed } from 'vue'
import MobileMegaMenu from './MobileMegaMenu.vue'
import { useCompanyProfile } from 'vuebase'
const companyProfile = useCompanyProfile()
const profile = computed(() => companyProfile.profile)
const props = defineProps(['menuData']);
// import { useHeaderStore } from 'vuebase'
import HeaderScript from "vuebase/templates/Header";
// const headerStore = useHeaderStore()

// const menuData = computed(() => headerStore.menuData.data)

const closeMenu=(()=>{
  document.querySelector('.sidebar_mobile_menu').classList.remove('active');
  document.querySelector('.overlay').classList.remove('active');
})


document.addEventListener("DOMContentLoaded", function () {
    const menu = document.querySelector(".menu");
    const mobMenu = document.querySelector(".mob-menu");
    const overlay = document.querySelector(".overlay");
    const closeBtn = document.getElementById("closeBtn");
    menu.addEventListener("click", function () {
        mobMenu.style.display = "block";
        overlay.classList.add("active");
    });
    closeBtn.addEventListener("click", function () {
        mobMenu.style.display = "none";
        overlay.classList.remove("active");
    });
});

</script>

<template>
  
  <div class="sidebar-menu-wrapper">
    <div class="cart_sidebar" id="cart-sidebar-content"></div>

    <div class="sidebar_mobile_menu">
      <!-- <button type="button" @click="closeMenu" aria-label="modal close"  class="close_btn closeBtn"><i class="fal fa-times"></i></button> -->

      <!-- <div class="msb_widget brand_logo text-center">
        <a class="mobile-menu-phone" href="tel:0289998878">
          <span>Call us</span> <strong class="">02 8999 8878</strong></a
        >
        <a href="">
          <img :src="profile.logo" width="200px" :alt="`View: ${profile.name}`" />
        </a>
      </div> -->

      <div class="msb_widget mobile_menu_list clearfix">
        <!-- <h3 class="title_text mb_15 text-uppercase"><i class="far fa-bars mr-2"></i> Menu List</h3> -->

        <ul class="ul_li clearfix mobilemenu ps-0 mt-5">
          <!-- @include('layouts.mobile_megamenu') -->
          <MobileMegaMenu v-if="props.menuData" :menuData="props.menuData"/>
        </ul>
      </div>

      <!-- @if(Auth::guard('customer')->check()) -->

     
      <!-- @endif -->
    </div>

    <div class="overlay"></div>
  </div>
</template>

<style>
.mobilemenu {
  position: relative;
  list-style: none;
}
.mobilemenu > li.menu_item_has_child {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.mobilemenu li.menu_item_has_child > ul {
  position: absolute;
  transform: translateX(120%);
  transition-duration: 500ms;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 1;
  width: 100%;
}
.mobilemenu li {
  border-bottom: 1px solid #f1f1f1;
}
.mobilemenu li.menu_item_has_child.active > ul {
  transform: translateX(120%);
}
.mobilemenu li.menu_item_has_child.active.current > ul {
  transform: translateX(0px);
}
.mobilemenu li.menu_item_has_child.active li.menu_item_has_child.active.current > ul {
  transform: translateX(-120%);
}
.mobilemenu li.menu_item_has_child.active,
.mobilemenu li.menu_item_has_child.active.current li {
  display: flex !important;
}
.mobilemenu li.menu_item_has_child.active > a,
.mobilemenu li.menu_item_has_child.active > .menu_open {
  display: none !important;
}
.mobilemenu li {
  border-bottom: 1px solid #f1f1f1;
}
.menu_item_has_child a, .mobilemenu a{
  padding: 15px;
    display: block;
    width: 100%;
    line-height: 1;
    font-size: 18px;
    font-weight: 600;
    color: #6b6b6b;
}
</style>
