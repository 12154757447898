

<script setup lang="ts">
import HeaderScript from "vuebase/templates/Header";
import SidebarMobileMenu from "@/components/template_01/Menu/SidebarMobileMenu.vue";
import HeaderSearch from "@/components/template_01/Header/HeaderSearch.vue";
import { ref } from "vue";
// import MobileMenu from "@/components/template_01/Header/MobileMenu.vue";

const emit = defineEmits(['CallOpenGarage']);
const openGarage = ()=>{
  emit('CallOpenGarage');
}
const {
  menuData, cartStore
} = HeaderScript.setup();

// Header Js
document.addEventListener("DOMContentLoaded", function () {
  window.onscroll = function () {
    showHeaderOnScroll();
  };
  let lastScrollTop = 0; // Tracks last scroll position

  function showHeaderOnScroll() {
    var header = document.querySelector(".vfr");
    const innerHeader = document.querySelector(".vfrfr");
    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;

    // Check if the screen width is greater than 768px
    const isDesktop = window.innerWidth > 768;

    if (
      isDesktop &&
      (document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300)
    ) {
      header.classList.add("active-header"); // Show header on desktop
    } else {
      header.classList.remove("active-header"); // Hide header
    }

    if (
      isDesktop &&
      currentScrollTop > 300 &&
      currentScrollTop < lastScrollTop
    ) {
      // Show the inner header when scrolling up after 300px on desktop
      innerHeader.classList.add("active-inner-header");
    } else {
      // Hide the inner header when scrolling down or before 300px
      innerHeader.classList.remove("active-inner-header");
    }

    // Update lastScrollTop to current scroll position
    lastScrollTop = currentScrollTop;
  }
});

document.addEventListener("DOMContentLoaded", function () {
    const menu = document.querySelector(".menu");
    const mobMenu = document.querySelector(".mob-menu");
    const overlay = document.querySelector(".overlay");
    const closeBtn = document.getElementById("closeBtn");
    menu.addEventListener("click", function () {
        mobMenu.style.display = "block";
        overlay.classList.add("active");
    });
    closeBtn.addEventListener("click", function () {
        mobMenu.style.display = "none";
        overlay.classList.remove("active");
    });
});
const searchBar = ref(false);

</script>

<template>
  <div class="row bg-primary d-xl-none vfrfm">
    <HeaderSearch v-if="searchBar==true"/>
    <div class="col-4">
      <router-link :to="{name: 'Home'}">
        <img
        src="@/assets/images-new/header/logo.webp"
        alt="Logo"
        height="50px"
        width="auto"
      />
      </router-link>
    </div>
    <div class="col-7 d-flex align-items-center justify-content-end gap-3">
      <div class="" @click="searchBar==false?searchBar=true:searchBar=false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2em"
          height="2em"
          viewBox="0 0 50 50"
        >
          <path
            fill="white"
            d="M23 36c-7.2 0-13-5.8-13-13s5.8-13 13-13s13 5.8 13 13s-5.8 13-13 13m0-24c-6.1 0-11 4.9-11 11s4.9 11 11 11s11-4.9 11-11s-4.9-11-11-11"
          />
          <path
            fill="white"
            d="m32.682 31.267l8.98 8.98l-1.414 1.414l-8.98-8.98z"
          />
        </svg>
      </div>
      <!-- Account, Cart, and Contact Section -->
      <div class="order-last">
        <!-- Login -->
        <div class="login">
          <router-link :to="{name: 'Login'}">
            <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAkCAYAAAAHKVPcAAAC6klEQVR4Ab1XAwwlMRQ827aN+Gzbti84Rmfbtm3btm3btucdJ01v093t/0nmY9s3nYdtX0OUaFvWGN+/fw+1Y8eOYjt37hyL7xPAW/z+DjwGTgHTgArbt28P44bXeOKuXbvKYtELsqgBrgNVrImAVxGw+GQhdwvYzd63b19EXyLOnDkTDmTrNeQSkaH4ro8IVcXvOvjdD9/nNGI2r1u3LrxnESCerhBexrNSTjaYUwZzrimip3gSAQ8rKkQ7kJoYGDNJYRxM38v2UlPGIugtuEoklzZv3hwdY24KOS7sbpATZ8Eb0lgEDMopaShuLMCBBxEq6kbEZPLgBI25huwfJGScsQjFsIcfEXCiG3EddxOJVxSJyj5FlCauV24i8ZkM8/kRgTrIy3XhJhKPSER5n5EoT1zv3IjYa7EmuhPXKTciBpPhaXm/fYg4SZGYYCwCecwpRoQqHgVUUnbNIq62bRgdJg/u7d27N57LgkwC2/vEcdLVjikQ1Uo0jpkK2bp1a2ruPfD7G1DI0ykKgkmKkPsQV/fIkSNh/9d7YE5bLPhSOfx6+eonQLBF00/cBWYA7YHmQGdgAcZeaObOlzT46qzgdSQQzfHYWU1etGhRaBvtXRYQrvQiQuwgJKtnEdIbgmA48NXB06fAVeChw5yvwsO9puk+kQYE5zWkt4EBQH7MiaKKxvPccmoCFzW251HU6YxEYHJ2Pjv+7BP4bkJvhSOkEOX+oXHkMYTkcBSxe/fuzGqFQ8BytHaxZdwtpMuG/TBFyAupM62IjRs3RtZcbgbwZB/HeTPZsLhn5XRyGoYoAobaEED8XRX+ESxCttnkUPqJJqxyfXIa1Imkltb4jPSnkjHd0f0aoUpABNYgvFxzsq48/3PVe0wi+pChdch1kdZ6LsUrucrPp52kJlACKPVfqccoICJ68HFNBoGMxhFyvLc8WKNeTgINrDOS1lwrIs6QqtZBEtGSRJyTB2/orlgrGCJQBzX5GvADSQUTgFSBjPYAAAAASUVORK5CYII="
            alt="customer icon"
          />
          </router-link>
        </div>
      </div>

      <div class="">
        <!-- Cart -->
        <span
          class="d-flex align-items-center text-decoration-none text-white gap-2"
        >
          <div class="cart position-relative" data-bs-toggle="offcanvas" data-bs-target="#cartSidebar" aria-controls="cartSidebar">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAjCAQAAAAp3XJaAAABj0lEQVR42sWUzytEURTHP56MH015D0VZkNUs0KT8B3bCTvYWUpIUhZWykK2ymERZ2FBspsRCt2QhIQuGsmY1GBHJj+ZcPXd64415U5xT9577fefTOfdHzwKVzRvUnDpVL+pJnasF1Zo1BwuvFTHFJWO0EKKcCIMcM0+JNzEbPMsMIYmeefzKGmKd4txwJ+Myx2mngjARlmTdzWhueEbGZbo45AO4oJ8p0SYp9YebiAIpRgT83sgZYNPhD7fIuEsqQ30nLnPUH66V8dqzmaSMYS+cbqeDZlnrE60XRXvEyG409DoNt7HDDhNGUpco2ocNvc/QezTsEMQqTfje+HDFmutHhp4QZV9i24QTRtI2va4vGvqmKNMSOxqu8VT2t1tvZQ2fESPGngc4Ef1A4rufYMUAA6x64C3RN/wrF9T2b+yNh+CwfqxfcFXecLrxMsosVZx+KzzxmicMthWgafeygsG6cmUhsG0FOC6jbQ2n/q5yQaet/2jOv13Vf8Bu29USrJDMw28yK4dx8vIMOIjZn8bbnrf/jgK2AAAAAElFTkSuQmCC"
              alt="user_icon"
              class="cart-icon"
            />
            <span class="cart-total-count-badge">{{ cartStore.itemsCount }}</span>
          </div>
        </span>
      </div>

      <div class="car">
        <div>
          <span
            
            @click="openGarage()"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 2048 2048"><path fill="#fff" d="M384 1152q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10m1280 0q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10m347-256l-66 65q2 5 10 30t19 59t25 73t24 71t18 54t7 22v650q0 27-10 50t-27 40t-41 28t-50 10h-128q-27 0-50-10t-40-27t-28-41t-10-50H384q0 27-10 50t-27 40t-41 28t-50 10H128q-27 0-50-10t-40-27t-28-41t-10-50v-650l7-21l18-54l24-72q13-39 24-73t20-59t10-30l-66-65H0V768h91l57 58l74-223q16-49 46-89t71-69t87-45t100-16h996q52 0 99 16t88 44t70 69t47 90l74 223l57-58h91v128zM526 512q-63 0-112 36t-70 95l-85 253h1530l-85-253q-20-59-69-95t-113-36zm882 1231l-104-207H744l-104 207v49h768zm512 49v-502l-6-18q-6-18-15-47t-21-61t-21-63t-17-51t-9-26H217q-2 5-9 26t-17 50t-21 63t-20 62t-16 46t-6 19v502h384v-79l152-305h720l152 305v79z"/></svg>
          </span>
        </div>
      </div>
    </div>
    <div class="col-1 d-flex align-items-center justify-content-end">
      <div class="menu">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.5em"
          height="1.5em"
          viewBox="0 0 24 24"
        >
          <path fill="white" d="M3 18v-2h18v2zm0-5v-2h18v2zm0-5V6h18v2z" />
        </svg>
      </div>
      <div id="MobMenu" class="container mob-menu">
        <div class="row p-4">
          <div class="d-flex justify-content-end" id="closeBtn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.5em"
              height="1.5em"
              viewBox="0 0 24 24"
            >
              <path
                fill="black"
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
              />
            </svg>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 d-flex flex-column align-items-center justify-content-center"
          >
            <div>
              <a
                class="mobile-menu-phone text-decoration-none fs-22"
                href="tel:0289998878"
                ><span class="fw-medium">Call us </span>
                <strong class="">02 8999 8878</strong></a
              >
            </div>
            <router-link :to="{name:'Home'}">
              <img
              src="@/assets/images-new/header/logo.webp"
              alt="Logo"
              class="img-fluid"
              width="200px"
              />
            </router-link>
          </div>
        </div>
         <SidebarMobileMenu :menuData="menuData" />
      </div>
      <div class="overlay"></div>
    </div>
  </div>
</template>

<style scoped>
.car{
  cursor: pointer;
}
.cart-total-count-badge{
  position: absolute;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #FF2400;
  font-weight: 900;
  background: rgba(0, 0, 0, 0) !important;
  top: calc(50% - 6px);
  font-size: 12px;
}

</style>