<script setup lang="ts">
import {ref,computed} from 'vue'
import { useCheckoutStore } from 'vuebase'
import CartDetailsScript from  "vuebase/templates/Cart/CartDetails"
const {useCartStore, fetchCart, cartData, removeFromCart, isObjectEmpty, dvidedsummary, toggleClass, openBundle, openAttributes,
    showOptions, CartDetail} = CartDetailsScript.setup()
const cartStore = useCartStore()
    const removeFromCartMiddleware = async(productId,product)=>{
  await removeFromCart(productId,product)
//   sendCartInsight();
}

const updateQuantityMiddleware = async(product,qty)=>{
  await fetchCart.updateQuantity(product, qty)
  useCheckoutStore().getConfig()
//   sendCartInsight();
}
const Totals = computed(() => {
    return fetchCart.cart.totals || {}
})
const showBundle = ref(false)

</script>

<template><!-- Cart Sidebar -->
    <!-- Cart Sidebar -->
<div class="offcanvas offcanvas-end" tabindex="-1" id="cartSidebar" aria-labelledby="cartSidebarLabel" aria-modal="true" role="dialog">
    <div class="d-flex justify-content-between align-items-center p-3">
        <div>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            <a href="#" class="text-decoration-none d-block text-dark">close</a>
        </div>
        <h5 id="cartSidebarLabel" class="fs-24 text-dark fw-bold d-flex align-items-center">Your Cart</h5>
        <router-link :to="{name:'Checkout'}" class="btn btn-custom btn-tran p-1 rounded-3 fs-18">Checkout <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
                    <path fill="none" stroke="#fff" d="M6 4.5L9.5 8L6 11.5" />
                </svg></router-link>
    </div>
    <div class="offcanvas-body">
        <div class="cart-body">
            <!-- Cart Header -->
            <div class="cart-header mb-2 d-flex justify-content-between">
                <span v-if="Totals.itemdiscount"><strong>Total Saved:</strong> <span class="text-secondary fw-semibold">${{Totals.itemdiscount.toFixed(2)}}</span></span>
                <span v-else><strong>Total Saved:</strong> <span class="text-secondary fw-semibold">$0.00</span></span>
                <span><strong>{{cartStore.itemsCount}}</strong> item(s)</span>
            </div>
            <!-- Cart Items List -->
            <ul class="list-unstyled cart-items mb-0" v-for="(product, productId) in cartData" :key="productId">
                <li class="cart-item gap-2">
                    <div class="gap-2 d-flex">
                    <!-- Image -->
                    <div class="cart-item-image me-3">
                        <router-link :to="{name:'Product', params:{slug: product.slug}}">
                            <img :src="product.image" alt="Subaru Forester Series 5 CL4" class="img-fluid" style="width: 100px;">
                        </router-link>
                    </div>
                    <!-- Content -->
                    <div class="cart-item-content w-100">
                        <h6 class="cart-item-title  lh-1">
                            <router-link :to="{name:'Product', params:{slug: product.slug}}" class="text-decoration-none fw-bold">{{product.name}}</router-link>
                            <a href="#" class="text-danger ms-2"><i class="fas fa-trash-alt"></i></a>
                        </h6>
                        <!-- start  -->
                        <div class="" v-if="product.pricefloat > 0">
                            <div class="quantity_input shopping_cart_qty_input w-75">
                                <span class="input_number_decrement" @click="updateQuantityMiddleware(product, product.qty - 1)">–</span>
                                <input name="itemQty" class="input_number" type="text"  :value="product.qty">
                                <span class="input_number_increment" @click="updateQuantityMiddleware(product, product.qty + 1)">+</span></div>
                        </div>
                        <!-- end  -->                        
                    </div>
                    <div class="cart-item-price d-flex flex-column align-items-end">
                        <i @click="removeFromCartMiddleware(productId,product)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
                                <path fill="#ff2400" d="m9.129 0l1.974.005c.778.094 1.46.46 2.022 1.078c.459.504.7 1.09.714 1.728h5.475a.69.69 0 0 1 .686.693a.69.69 0 0 1-.686.692l-1.836-.001v11.627c0 2.543-.949 4.178-3.041 4.178H5.419c-2.092 0-3.026-1.626-3.026-4.178V4.195H.686A.69.69 0 0 1 0 3.505c0-.383.307-.692.686-.692h5.47c.014-.514.205-1.035.554-1.55C7.23.495 8.042.074 9.129 0m6.977 4.195H3.764v11.627c0 1.888.52 2.794 1.655 2.794h9.018c1.139 0 1.67-.914 1.67-2.794zM6.716 6.34c.378 0 .685.31.685.692v8.05a.69.69 0 0 1-.686.692a.69.69 0 0 1-.685-.692v-8.05c0-.382.307-.692.685-.692m2.726 0c.38 0 .686.31.686.692v8.05a.69.69 0 0 1-.686.692a.69.69 0 0 1-.685-.692v-8.05c0-.382.307-.692.685-.692m2.728 0c.378 0 .685.31.685.692v8.05a.69.69 0 0 1-.685.692a.69.69 0 0 1-.686-.692v-8.05a.69.69 0 0 1 .686-.692M9.176 1.382c-.642.045-1.065.264-1.334.662c-.198.291-.297.543-.313.768l4.938-.001c-.014-.291-.129-.547-.352-.792c-.346-.38-.73-.586-1.093-.635z" />
                            </svg></i>
                        <strong class="lh-1 mt-2 fs-20">${{ (product.pricefloat * product.qty).toFixed(2) }}</strong>
                        <del v-if="product.rpfloat > product.pricefloat" class="product-head-price lh-1 fw-normal text-secondary">${{ (product.rpfloat * product.qty).toFixed(2) }}</del>
                    </div>
                </div>
                    <div v-if="product.bundle!=undefined && product.bundle.length>0" class="mt-2 bundeDetails">
                            <div @click="showBundle==false?showBundle=true:showBundle=false" :class="{'active':showBundle==true}" class="text-center open_bundeDetails ms-4 fw-bolder fs-6">
                                What's in the Bundle
                                <svg width="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M831.872 340.864L512 652.672L192.128 340.864a30.59 30.59 0 0 0-42.752 0a29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728a30.59 30.59 0 0 0-42.752 0z"/></svg>
                            </div>
                            <div class="bundleitems" :class="{'active':showBundle==true}">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th width="10%" scope="col"></th>
                                        <th width="65%" scope="col">Part Name</th>
                                        <th width="10%" scope="col">Qty</th>
                                        <th class="text-end" width="15%" scope="col">Unit Price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <!-- Loop through each item in the product bundle array -->
                                    <tr v-for="(item, index) in product.bundle" :key="index">
                                        <td scope="row">
                                        <img width="50px" :src="item.image" class="img-fluid bundle_image" :alt="item.name" />
                                        </td>
                                        <td class="align-middle">
                                        <h6><router-link :to="{name:'Product',params:{slug:item.slug}}" class="fw-bold text-primary mb-0">{{ item.name }}</router-link></h6>
                                        <div class="small"> SKU: {{ item.sku }}</div>
                                        </td>
                                        <td class="align-middle">{{ item.qty }}</td>
                                        <td class="align-middle text-end fw-bold">{{ item.price }}
                                        <del v-if="item.rpfloat > item.floatprice" class="product-head-price d-block fw-normal text-secondary">{{ item.rp }}</del>
                                        </td>
                                        
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </li>
            </ul>

            <!-- Cart Footer -->
            <div class="cart-footer d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                    <strong>Your Cart Total:</strong>
                    <span>{{Totals.gstlabel}}(incl.):</span>
                </div>
                <div class="d-flex gap-3">
                    <div class="d-flex flex-column">
                        <strong v-if="Totals && Totals.total">${{Totals.total.toFixed(2)}}</strong>
                        <strong v-else>$0.00</strong>
                        <span v-if="Totals && Totals.gst">${{Totals.gst.toFixed(2)}}</span>
                        <span v-else>$0.00</span>
                    </div>
                    <!-- Cart Button  -->
                    <router-link :to="{name:'Checkout'}" class="btn btn-custom btn-tran pb-0">Checkout<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
                                <path fill="none" stroke="#fff" d="M6 4.5L9.5 8L6 11.5" />
                            </svg></router-link>
                </div>
            </div>
        </div>
    </div> 
</div>
</template>
<style scoped lang="scss">
.open_bundeDetails{
    cursor: pointer;
    color:var(--bs-primary) !important;
    &:hover{
        color:var(--bs-secondary) !important;
    }
    &.active{
        color:var(--bs-secondary) !important;
        &:hover{
            color:var(--bs-primary) !important;
        }
    }
}
.bundleitems {
    max-height: 0;
    overflow: hidden;
    transition-duration: 500ms;
    &.active{
        max-height: 500px;
        overflow: visible;
    }
}
</style>