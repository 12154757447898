<script setup>
import ChildItem from './ChildItem.vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const props = defineProps({
  menuData: Array
})
</script>
<template>

  <li class="menu_item_has_child align-items-center" v-if="props.menuData.title != 'Column'">
    <router-link :to="'/' + props.menuData.url">{{ props.menuData.title }}</router-link>
    <span class="menu_open"><i class="fas fa-plus-square">
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 448 512">
	<path fill="#000" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48m-32 252c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92H92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12z" />
</svg>
        
    </i></span>
    <ul class="ps-0">
      <li class="mb-2 pb-2">
        <a class="mobile-menu-back" rel="nofollow" href="#"><i class="fas fa-chevron-left">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 320 512">
	<path fill="#6b6b6b" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256L246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
</svg>
        </i> Back</a>
      </li>
      <li class="">
        <router-link :to="'/' + props.menuData.url">{{ props.menuData.title }}</router-link>
      </li>
      <template v-for="item in props.menuData.children">
        <ChildItem :menuData="item" v-if="item.children" />
        <li v-else="">
          <router-link class="nav-link nav-child-link child-link"
            :class="{ 'nav-link-active': route.path.includes(item.url) }" :to="'/' + item.url">
            {{ item.title }}
          </router-link></li>
      </template>
    </ul>
  </li>
  <template v-else="" v-for="item in props.menuData.children">
    <ChildItem :menuData="item" v-if="item.children" />
    <li v-else=""><router-link class="nav-link nav-child-link child-link"
        :class="{ 'nav-link-active': route.path.includes(item.url) }" :to="'/' + item.url">
        {{ item.title }}
      </router-link></li>
  </template>
</template>