<script setup lang="ts">
import { ref, onMounted } from "vue";
import HeaderScript from "vuebase/templates/Header";
import CartSideBar from "@/components/template_01/Cart/CartSideBar.vue";
import DesktopMenu from "@/components/template_01/Header/DesktopMenu.vue";
import StickyMenu from "@/components/template_01/Header/StickyMenu.vue";
import HeaderMobile from "@/components/template_01/Header/HeaderMobile.vue";
import MyGarage from "@/components/template_01/Header/MyGarage.vue";
import SearchResult from "@/components/template_01/Header/SearchResult.vue";
import { useCarsStore } from "vuebase";
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const {
  wordpressUrl,
  NavMenu,
  companyProfile,
  menuData,
  authStore,
  cartStore,
  userStore,
  userData,
  mobile_menu,
  MainMenu,
  OpenMobileMenu,
  bodyOwerflow,
  useSearch,
  getSearchData,
  handleSeachInput
} = HeaderScript.setup();
import { Swiper, SwiperSlide } from "swiper/vue";
// core version + navigation, pagination modules:
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const scrollPosition = ref(0);
const OpenCareGaragae = ref(false);
// Method to handle scroll events
const handleScroll = () => {
  scrollPosition.value = window.scrollY || document.documentElement.scrollTop;
};
const searchValue = ref('')
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  setTimeout(() => {
    searchValue.value = route.query.search ? route.query.search : ''
  }, 2000);


});

const bodyOverflow = () => {
  if (mobile_menu.value == true) {
    document.querySelector("body").style.overflow = "hidden";
  } else {
    document.querySelector("body").style.overflow = "auto";
  }
};


const fromTop = ref(false)
const CallGaragae = () => {
  OpenCareGaragae.value = false;
  setTimeout(() => {
    OpenCareGaragae.value = true;
  }, 100);
};

const resultshow = ref(true)
const hideSearchResult = (() => {
  resultshow.value = false
  fromTop.value = false
})
const triggerInputOnMouseEnter = (() => {
  resultshow.value = true;
  getSearchData('');
})
</script>

<template>
  <header class="container-fluid pb-2">
    <!-- Header Part for desktop only  -->
    <div class="row bg-primary d-none vfr">
      <div class="col-lg-auto">
        <router-link :to="{ name: 'Home' }">
          <img src="@/assets/images-new/header/logo.webp" alt="Roof Rack World Logo" height="85" width="218" class="logo img-fluid" />
        </router-link>
      </div>
      <div class="col-lg ps-2 d-flex align-items-center justify-content-between frsc">
        <div class="row w-100 d-flex align-items-center justify-content-lg-between flex-nowrap">
          <div class="col-lg-3 col-xl-5">
            <form action="/search" class="d-flex position-relative">
              <input name="search" @input="handleSeachInput(searchValue), resultshow = true, fromTop = true"
                v-model="searchValue" @mouseleave="getSearchData(searchValue)"
                @focusin="handleSeachInput(searchValue), resultshow = true, fromTop = true"
                class="form-control me-2 search-box-input" type="search" placeholder="Search" aria-label="Search" />
              <span @click="searchValue = '', getSearchData(''), resultshow = false" v-if="searchValue != ''" class=""
                style="position:absolute;right:90px;top:calc(50% - 10px);height:20px;width:20px;" type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
                  <path fill="currentColor"
                    d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z" />
                </svg>
              </span>
              <button class="btn btn-secondary rounded-5 d-flex justify-content-center align-items-center search-btn"
                type="submit">
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 50 50">
                    <path fill="white"
                      d="M23 36c-7.2 0-13-5.8-13-13s5.8-13 13-13s13 5.8 13 13s-5.8 13-13 13m0-24c-6.1 0-11 4.9-11 11s4.9 11 11 11s11-4.9 11-11s-4.9-11-11-11" />
                    <path fill="white" d="m32.682 31.267l8.98 8.98l-1.414 1.414l-8.98-8.98z" />
                  </svg>
                </i>
              </button>
            </form>
          </div>
          <!-- Account, Cart, and Contact Section -->
          <div class="col-lg-auto px-lg-0 px-xl-3">
            <router-link :to="{ name: 'Login' }" class="d-flex flex-row gap-2">
              <!-- Login -->
              <div class="login">
                <img height="36" width="33"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAkCAYAAAAHKVPcAAAC6klEQVR4Ab1XAwwlMRQ827aN+Gzbti84Rmfbtm3btm3btucdJ01v093t/0nmY9s3nYdtX0OUaFvWGN+/fw+1Y8eOYjt37hyL7xPAW/z+DjwGTgHTgArbt28P44bXeOKuXbvKYtELsqgBrgNVrImAVxGw+GQhdwvYzd63b19EXyLOnDkTDmTrNeQSkaH4ro8IVcXvOvjdD9/nNGI2r1u3LrxnESCerhBexrNSTjaYUwZzrimip3gSAQ8rKkQ7kJoYGDNJYRxM38v2UlPGIugtuEoklzZv3hwdY24KOS7sbpATZ8Eb0lgEDMopaShuLMCBBxEq6kbEZPLgBI25huwfJGScsQjFsIcfEXCiG3EddxOJVxSJyj5FlCauV24i8ZkM8/kRgTrIy3XhJhKPSER5n5EoT1zv3IjYa7EmuhPXKTciBpPhaXm/fYg4SZGYYCwCecwpRoQqHgVUUnbNIq62bRgdJg/u7d27N57LgkwC2/vEcdLVjikQ1Uo0jpkK2bp1a2ruPfD7G1DI0ykKgkmKkPsQV/fIkSNh/9d7YE5bLPhSOfx6+eonQLBF00/cBWYA7YHmQGdgAcZeaObOlzT46qzgdSQQzfHYWU1etGhRaBvtXRYQrvQiQuwgJKtnEdIbgmA48NXB06fAVeChw5yvwsO9puk+kQYE5zWkt4EBQH7MiaKKxvPccmoCFzW251HU6YxEYHJ2Pjv+7BP4bkJvhSOkEOX+oXHkMYTkcBSxe/fuzGqFQ8BytHaxZdwtpMuG/TBFyAupM62IjRs3RtZcbgbwZB/HeTPZsLhn5XRyGoYoAobaEED8XRX+ESxCttnkUPqJJqxyfXIa1Imkltb4jPSnkjHd0f0aoUpABNYgvFxzsq48/3PVe0wi+pChdch1kdZ6LsUrucrPp52kJlACKPVfqccoICJ68HFNBoGMxhFyvLc8WKNeTgINrDOS1lwrIs6QqtZBEtGSRJyTB2/orlgrGCJQBzX5GvADSQUTgFSBjPYAAAAASUVORK5CYII="
                  alt="customer icon" />
              </div>
              <div class="me-4 d-flex align-items-center text-decoration-none text-white fw-bolder fs-20"
                v-if="authStore.user && authStore.user.firstname">
                {{ authStore.user.firstname }}
              </div>
              <div class="me-4 d-flex align-items-center text-decoration-none text-white fw-bolder fs-20" v-else>
                Login
              </div>
            </router-link>
          </div>

          <div class="col-lg-auto px-3">
            <!-- Cart -->

            <a href="#" class="me-3 d-flex align-items-center text-decoration-none text-white gap-3"
              data-bs-toggle="offcanvas" data-bs-target="#cartSidebar" aria-controls="cartSidebar">
              <div class="cart position-relative">
                <img width="30" height="35"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAjCAQAAAAp3XJaAAABj0lEQVR42sWUzytEURTHP56MH015D0VZkNUs0KT8B3bCTvYWUpIUhZWykK2ymERZ2FBspsRCt2QhIQuGsmY1GBHJj+ZcPXd64415U5xT9577fefTOfdHzwKVzRvUnDpVL+pJnasF1Zo1BwuvFTHFJWO0EKKcCIMcM0+JNzEbPMsMIYmeefzKGmKd4txwJ+Myx2mngjARlmTdzWhueEbGZbo45AO4oJ8p0SYp9YebiAIpRgT83sgZYNPhD7fIuEsqQ30nLnPUH66V8dqzmaSMYS+cbqeDZlnrE60XRXvEyG409DoNt7HDDhNGUpco2ocNvc/QezTsEMQqTfje+HDFmutHhp4QZV9i24QTRtI2va4vGvqmKNMSOxqu8VT2t1tvZQ2fESPGngc4Ef1A4rufYMUAA6x64C3RN/wrF9T2b+yNh+CwfqxfcFXecLrxMsosVZx+KzzxmicMthWgafeygsG6cmUhsG0FOC6jbQ2n/q5yQaet/2jOv13Vf8Bu29USrJDMw28yK4dx8vIMOIjZn8bbnrf/jgK2AAAAAElFTkSuQmCC"
                  alt="user_icon" class="cart-icon" />
                <span class="cart-total-count-badge" data-v-inspector="src/components/template_01/Header.vue:273:21">{{
                  cartStore.itemsCount }}</span>
              </div>
              <div class="d-flex flex-column align-items-start gap-0">
                <span class="fw-medium fs-20">Shopping Cart</span>
                <span class="fw-bolder fs-20 lh-1" v-if="cartStore && cartStore.cart.totals">${{
                  cartStore.cart.totals.total.toFixed(2) }}</span>
                <span class="fw-bolder fs-20 lh-1" v-else>$0.00</span>
              </div>
            </a>
          </div>
          <div class="col-lg-auto text-left">
            <div class="d-flex align-items-center justify-content-end gap-2">
              <div>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 2048 2048"><path fill="#ffffff" d="M384 1152q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10m1280 0q27 0 50 10t40 27t28 41t10 50q0 27-10 50t-27 40t-41 28t-50 10q-27 0-50-10t-40-27t-28-41t-10-50q0-27 10-50t27-40t41-28t50-10m347-256l-66 65q2 5 10 30t19 59t25 73t24 71t18 54t7 22v650q0 27-10 50t-27 40t-41 28t-50 10h-128q-27 0-50-10t-40-27t-28-41t-10-50H384q0 27-10 50t-27 40t-41 28t-50 10H128q-27 0-50-10t-40-27t-28-41t-10-50v-650l7-21l18-54l24-72q13-39 24-73t20-59t10-30l-66-65H0V768h91l57 58l74-223q16-49 46-89t71-69t87-45t100-16h996q52 0 99 16t88 44t70 69t47 90l74 223l57-58h91v128zM526 512q-63 0-112 36t-70 95l-85 253h1530l-85-253q-20-59-69-95t-113-36zm882 1231l-104-207H744l-104 207v49h768zm512 49v-502l-6-18q-6-18-15-47t-21-61t-21-63t-17-51t-9-26H217q-2 5-9 26t-17 50t-21 63t-20 62t-16 46t-6 19v502h384v-79l152-305h720l152 305v79z"/></svg>
                </span>
              </div>
              <div class="d-flex flex-column cursor-pointer" @click="CallGaragae()">
                <span class="text-white fw-bold fs-18" style="line-height: 0.7">My Garage</span>
                <span class="text-white fw-light text-break" v-if="useCarsStore().selected_car && useCarsStore().selected_car.car">
                  {{(useCarsStore().selected_car.car.name).substring(0,20)}}...
                </span>
                <span class="text-white fw-light" v-else> Select Your Cars </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-auto d-flex align-items-center">
        <a href="tel:(02) 8999 8878" class="d-flex align-items-center text-muted me-1">
          <!-- Contact -->
          <div class="d-flex align-items-center text-muted me-1">
            <div>
              <img class="phone-cl" width="40" height="40"
                src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40px' height='40px' viewBox='0 0 24 24'%3E%3Cpath fill='rgb(193, 193, 193)' fill-rule='evenodd' d='M5.84 9.856a17.22 17.22 0 0 0 8.922 8.663l.012.005l.764.34a2.25 2.25 0 0 0 2.74-.737l1.274-1.763a.25.25 0 0 0-.046-.341l-2.224-1.795a.25.25 0 0 0-.358.046l-.866 1.168a.75.75 0 0 1-.912.237a13.4 13.4 0 0 1-6.67-6.67a.75.75 0 0 1 .237-.912L9.88 7.23a.25.25 0 0 0 .046-.358L8.132 4.648a.25.25 0 0 0-.341-.046l-1.773 1.28a2.25 2.25 0 0 0-.732 2.756zm8.33 10.041a18.72 18.72 0 0 1-9.693-9.416l-.002-.002l-.554-1.22A3.75 3.75 0 0 1 5.14 4.666l1.773-1.28a1.75 1.75 0 0 1 2.386.32l1.795 2.225a1.75 1.75 0 0 1-.32 2.505l-.67.496a11.9 11.9 0 0 0 5.118 5.118l.497-.67a1.75 1.75 0 0 1 2.504-.32l2.225 1.795a1.75 1.75 0 0 1 .32 2.387l-1.274 1.764a3.75 3.75 0 0 1-4.566 1.229z' clip-rule='evenodd'/%3E%3C/svg%3E" alt="call (02) 8999 8878" />
            </div>
          </div>
          <div class="d-flex flex-column gap-1">
            <span class="mb-0 fw- text-white fs-20" style="line-height: 0.5">Call us
            </span>
            <span class="fw-bold text-white fs-20 lh-1 mb-0">(02) 8999 8878</span>
          </div>
        </a>
      </div>
      <!-- Sticky Menu start  -->
      <StickyMenu />
      <!-- Sticky Menu End  -->
    </div>

    <!-- Header Part for Mobile only  -->
    <HeaderMobile @CallOpenGarage="CallGaragae()" />

    <!-- Header Part for desktop only  -->
    <div class="container fcon d-none d-xl-block shadow">
      <div class="row fr">
        <!-- Logo Section -->
        <div class="col-md-3 frfc">
          <div class="brand-logo d-flex align-items-center justify-content-center border-end">
            <router-link :to="{ name: 'Home' }">
              <img src="@/assets/images/logo.webp" alt="Roof Rack & Towbar World" height="97" width="248" class="img-fluid" />
            </router-link>
          </div>
        </div>

        <!-- Search Bar Section -->
        <div class="col-md-9 ps-4 frsc">
          <div class="row border-bottom py-3 ps-2 pe-4">
            <div class="col-md-6 col-lg-5 g-0">
              <form action="/search" class="d-flex position-relative">
                <input name="search" @input="handleSeachInput(searchValue), resultshow = true" v-model="searchValue"
                  @mouseleave="getSearchData(searchValue)" @focusin="handleSeachInput(searchValue), resultshow = true"
                  class="form-control ps-4 search-box-input" type="search" placeholder="Search" aria-label="Search" />
                <span @click="searchValue = '', getSearchData(''), resultshow = false" v-if="searchValue != ''" class="d-none"
                  style="position:absolute;right:90px;top:calc(50% - 10px);height:20px;width:20px;" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
                    <path fill="currentColor"
                      d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z" />
                  </svg>
                </span>
                <button class="btn btn-secondary rounded-5 d-flex justify-content-center align-items-center search-btn"
                  type="submit">
                  <i>
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 50 50">
                      <path fill="white"
                        d="M23 36c-7.2 0-13-5.8-13-13s5.8-13 13-13s13 5.8 13 13s-5.8 13-13 13m0-24c-6.1 0-11 4.9-11 11s4.9 11 11 11s11-4.9 11-11s-4.9-11-11-11" />
                      <path fill="white" d="m32.682 31.267l8.98 8.98l-1.414 1.414l-8.98-8.98z" />
                    </svg>
                  </i>
                </button>
              </form>
            </div>
            <div class="col-md-6 col-lg-7">
              <!-- Account, Cart, and Contact Section -->
              <div class="d-flex justify-content-end align-items-center frtc">
                <!-- Login -->
                <router-link :to="{ name: 'Login' }"
                  class="me-4 d-flex align-items-center text-decoration-none text-danger fw-bolder gap-2 fs-5">
                  <img height="36" width="33"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAkCAYAAAAHKVPcAAAC6klEQVR4Ab1XAwwlMRQ827aN+Gzbti84Rmfbtm3btm3btucdJ01v093t/0nmY9s3nYdtX0OUaFvWGN+/fw+1Y8eOYjt37hyL7xPAW/z+DjwGTgHTgArbt28P44bXeOKuXbvKYtELsqgBrgNVrImAVxGw+GQhdwvYzd63b19EXyLOnDkTDmTrNeQSkaH4ro8IVcXvOvjdD9/nNGI2r1u3LrxnESCerhBexrNSTjaYUwZzrimip3gSAQ8rKkQ7kJoYGDNJYRxM38v2UlPGIugtuEoklzZv3hwdY24KOS7sbpATZ8Eb0lgEDMopaShuLMCBBxEq6kbEZPLgBI25huwfJGScsQjFsIcfEXCiG3EddxOJVxSJyj5FlCauV24i8ZkM8/kRgTrIy3XhJhKPSER5n5EoT1zv3IjYa7EmuhPXKTciBpPhaXm/fYg4SZGYYCwCecwpRoQqHgVUUnbNIq62bRgdJg/u7d27N57LgkwC2/vEcdLVjikQ1Uo0jpkK2bp1a2ruPfD7G1DI0ykKgkmKkPsQV/fIkSNh/9d7YE5bLPhSOfx6+eonQLBF00/cBWYA7YHmQGdgAcZeaObOlzT46qzgdSQQzfHYWU1etGhRaBvtXRYQrvQiQuwgJKtnEdIbgmA48NXB06fAVeChw5yvwsO9puk+kQYE5zWkt4EBQH7MiaKKxvPccmoCFzW251HU6YxEYHJ2Pjv+7BP4bkJvhSOkEOX+oXHkMYTkcBSxe/fuzGqFQ8BytHaxZdwtpMuG/TBFyAupM62IjRs3RtZcbgbwZB/HeTPZsLhn5XRyGoYoAobaEED8XRX+ESxCttnkUPqJJqxyfXIa1Imkltb4jPSnkjHd0f0aoUpABNYgvFxzsq48/3PVe0wi+pChdch1kdZ6LsUrucrPp52kJlACKPVfqccoICJ68HFNBoGMxhFyvLc8WKNeTgINrDOS1lwrIs6QqtZBEtGSRJyTB2/orlgrGCJQBzX5GvADSQUTgFSBjPYAAAAASUVORK5CYII="
                    alt="customer icon" />
                  <span v-if="authStore.user && authStore.user.firstname">
                    {{ authStore.user.firstname }}
                  </span>
                  <span v-else>
                    Login
                  </span>
                </router-link>

                <!-- Cart -->
                <a href="#" class="me-4 d-flex align-items-center text-decoration-none text-danger gap-2"
                  data-bs-toggle="offcanvas" data-bs-target="#cartSidebar" aria-controls="cartSidebar">
                  <div class="position-relative">
                    <span class="cart-total-count-badge">{{ cartStore.itemsCount }}</span>
                    <img width="30" height="35"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAjCAQAAAAp3XJaAAABj0lEQVR42sWUzytEURTHP56MH015D0VZkNUs0KT8B3bCTvYWUpIUhZWykK2ymERZ2FBspsRCt2QhIQuGsmY1GBHJj+ZcPXd64415U5xT9577fefTOfdHzwKVzRvUnDpVL+pJnasF1Zo1BwuvFTHFJWO0EKKcCIMcM0+JNzEbPMsMIYmeefzKGmKd4txwJ+Myx2mngjARlmTdzWhueEbGZbo45AO4oJ8p0SYp9YebiAIpRgT83sgZYNPhD7fIuEsqQ30nLnPUH66V8dqzmaSMYS+cbqeDZlnrE60XRXvEyG409DoNt7HDDhNGUpco2ocNvc/QezTsEMQqTfje+HDFmutHhp4QZV9i24QTRtI2va4vGvqmKNMSOxqu8VT2t1tvZQ2fESPGngc4Ef1A4rufYMUAA6x64C3RN/wrF9T2b+yNh+CwfqxfcFXecLrxMsosVZx+KzzxmicMthWgafeygsG6cmUhsG0FOC6jbQ2n/q5yQaet/2jOv13Vf8Bu29USrJDMw28yK4dx8vIMOIjZn8bbnrf/jgK2AAAAAElFTkSuQmCC"
                      alt="user_icon" class="cart-icon" />
                  </div>
                  <div class="d-flex flex-column">
                    <span class="fw-medium fs-5 text-nowrap lh-1">Shopping Cart</span>
                    <span class="fw-bolder fs-5 text-nowrap" v-if="cartStore && cartStore.cart.totals">${{
                      cartStore.cart.totals.total.toFixed(2) }}</span>
                    <span class="fw-bolder fs-5 text-nowrap" v-else>$0.00</span>
                  </div>
                </a>

                <!-- Contact -->
                <a href="tel:(02) 8999 8878" class="d-flex align-items-center text-muted">
                  <div>
                    <img class="phone-cl" height="48" width="48"
                      src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3em' height='3em' viewBox='0 0 24 24'%3E%3Cpath fill='rgb(193, 193, 193)' fill-rule='evenodd' d='M5.84 9.856a17.22 17.22 0 0 0 8.922 8.663l.012.005l.764.34a2.25 2.25 0 0 0 2.74-.737l1.274-1.763a.25.25 0 0 0-.046-.341l-2.224-1.795a.25.25 0 0 0-.358.046l-.866 1.168a.75.75 0 0 1-.912.237a13.4 13.4 0 0 1-6.67-6.67a.75.75 0 0 1 .237-.912L9.88 7.23a.25.25 0 0 0 .046-.358L8.132 4.648a.25.25 0 0 0-.341-.046l-1.773 1.28a2.25 2.25 0 0 0-.732 2.756zm8.33 10.041a18.72 18.72 0 0 1-9.693-9.416l-.002-.002l-.554-1.22A3.75 3.75 0 0 1 5.14 4.666l1.773-1.28a1.75 1.75 0 0 1 2.386.32l1.795 2.225a1.75 1.75 0 0 1-.32 2.505l-.67.496a11.9 11.9 0 0 0 5.118 5.118l.497-.67a1.75 1.75 0 0 1 2.504-.32l2.225 1.795a1.75 1.75 0 0 1 .32 2.387l-1.274 1.764a3.75 3.75 0 0 1-4.566 1.229z' clip-rule='evenodd'/%3E%3C/svg%3E" />
                  </div>
                  <div class="d-flex flex-column">
                    <span class="fw-medium text-danger fs-5 text-nowrap lh-1">Call us </span>
                    <span class="fw-bold text-danger fs-5 text-nowrap">(02) 8999 8878</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <SearchResult :class="{ 'searchfixed': fromTop == true }" @HideSearch="hideSearchResult" :searchValue="searchValue"
            :resultshow="resultshow" />
          <!-- Desktop Menu Menu Component -->
          <DesktopMenu @CallOpenGarage="CallGaragae()" />
        </div>
      </div>
    </div>
  </header>
  <component :is="CartSideBar" />
  <component v-if="OpenCareGaragae == true" :is="MyGarage" />
  <Transition>
    <div class="backtotop bg_carparts_red" v-if="scrollPosition > 350">
      <a href="#" class="scroll">
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="-5 -4.5 24 24">
          <path fill="white"
            d="m6 4.071l-3.95 3.95A1 1 0 0 1 .636 6.607L6.293.95a.997.997 0 0 1 1.414 0l5.657 5.657A1 1 0 0 1 11.95 8.02L8 4.07v9.586a1 1 0 1 1-2 0z" />
        </svg>
      </a>
    </div>
  </Transition>
</template>
<style scoped lang="scss">
.cursor-pointer{
  cursor: pointer;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.backtotop {
  right: 15px;
  width: 50px;
  height: 50px;
  z-index: 600;
  bottom: 50px;
  font-size: 16px;
  position: fixed;
  border-radius: 100%;
  box-shadow: 0px 10px 30px 1px rgba(0, 0, 0, 0.15);
  background-color: var(--bs-secondary);

  .scroll {
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    color: #ffffff;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: var(--bs-dark);
  }
}
.vfr {
  img.logo{
    width: auto;
    height: 50px;
  }
}
.cart-total-count-badge {
  position: absolute;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #ff2400;
  font-weight: 900;
  background: rgba(0, 0, 0, 0) !important;
  top: calc(50% - 4px);
  font-size: 20px;
}
</style>
