<script setup lang="ts">
import FooterScript from "vuebase/templates/Footer";
const { subscriptionStore, email, successMessage, errorMessage, handleSubmit } = FooterScript.setup();
</script>
<template>
  <div class="row mb-5 position-absolute bg-secondary px-2 py-3 rounded-3 w-100 fr">
    <div class="col-md-4 text-center d-flex align-items-center justify-content-center justify-content-lg-start frfc">
      <h2 class="mb-md-0">
        Subscribe Now
      </h2>
    </div>
    <div class="col-md-8 frsc">
      <form @submit.prevent="handleSubmit" class="d-flex justify-content-center">
        <div
          class="d-flex flex-column align-items-center gap-3 flex-lg-row justify-content-center position-relative w-100 bg-white rounded-pill">
          <input v-model="email" type="email" class="form-control w-100 rounded-pill me-2 px-4 border-0"
            placeholder="Enter Your Email" aria-label="Email" required />
          <button class="btn position-lg-absolute" type="submit">
            Subscribe
          </button>
        </div>
      </form>
      <p v-if="successMessage" class="bg-white rounded-5 px-2 py-2 mt-2" style="color:green;">{{ successMessage }}</p>
      <p v-else-if="errorMessage" class="bg-white rounded-5 px-2 py-2 mt-2" style="color:red;">The email has already
        been subscribed.</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media only screen and (max-width:768px) {
  footer .fcon .fr {
    top: -250px;

    h2 {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }

  button {
    transform: translateY(0%) !important;
    padding: 0 !important;
  }
}
</style>